import React from 'react';
import styles from './print.module.css';
import QRCode from 'qrcode.react';

export class TicketInformationToPrint extends React.PureComponent {
  timeElapsed = Date.now();
  today = new Date(this.timeElapsed);
  todayDate = this.today.toDateString();
  render() {
    return (
      <div className={styles.printContainer}>
        {this.props.data?.getTicketsPrint?.map((ticket, i) => {
          return (
            <div className={styles.print} key={ticket.id}>
              <span className={styles.departure}>{ticket.from_time}</span>
              <span className={styles.date}>{ticket.from_date}</span>
              <span className={styles.seatNumber}>
                {ticket.seat_number}
                {'-'}
                {ticket.seat_type.name_en}
              </span>
              <span className={styles.price}>{`${ticket.price} EGP`}</span>
              <span className={styles.priceTop}>{`${ticket.price} EGP`}</span>
              <span className={styles.ticketCodeTop}>{ticket.code}</span>
              <span className={styles.fromLocation}>
                {ticket.from_location.city.name_en}
                <br />
                {ticket.from_location.name_en}{' '}
              </span>
              <span className={styles.toLocation}>
                {ticket.to_location.city.name_en}
                <br />
                {ticket.to_location.name_en}
              </span>
              <span className={styles.tripNumber}>{ticket.trip.ref_code}</span>
              <span className={styles.issueDateTop}>{this.todayDate}</span>
              <span  className={styles.discountPrice}>Promo: {ticket?.promo_code?.code}</span>
              <span className={styles.issueDateBottom}>{this.todayDate}</span>
              <span className={styles.tripId}>{ticket.code}</span>
              <QRCode value={ticket.qr_code_name} className={styles.qrCode} />
            </div>
          );
        })}
      </div>
    );
  }
}
